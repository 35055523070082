import React, { useState } from "react";
import { styled } from "@mui/system";
import { AppBar, Toolbar, Typography, Button, Card, CardContent, CardMedia, Grid, Box, Container, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import Thrivelogo from "./thrivehub.png";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: "transparent",
  boxShadow: "none",
}));

const HeroSection = styled(Box)(({ theme }) => ({
  backgroundImage: "url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1300&q=80')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "60vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black", // Change font color to black
  textAlign: "center",
}));

const Logo = styled("img")(({ theme }) => ({
  height: "50px",
  marginRight: theme.spacing(2),
}));

const ServiceCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const CardLogo = styled("img")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  height: "30px",
}));

// Define the Service type
interface Service {
  title: string;
  description: string;
  image: string;
}

const services: Service[] = [
  { title: "Business Accounting Packages", image: "https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", description: "Tailored Small Business Clubs: Micro Business Club, SBC 150, SBC 250, SBC 400" },
  { title: "Statutory Services", image: "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", description: "Accounts Preparation, Corporation Tax Returns, Payroll Management, VAT Returns" },
  { title: "Tax Planning & Compliance", image: "https://images.unsplash.com/photo-1554224154-26032ffc0d07?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1226&q=80", description: "Comprehensive Tax Planning Advice, Patent Box Tax Relief Consultancy" },
  { title: "Business Support", image: "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", description: "Personalised Business Support, Access to a Senior Thrive Consultant" },
  { title: "Procurement Consultancy", image: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", description: "Expert guidance on procurement strategies to optimise costs and improve efficiency" },
  { title: "Specialist Insurance Concierge", image: "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80", description: "Partnering with businesses to offer personalised insurance guidance" },
];

const HomePage = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const handleCardClick = (service: Service) => {
    setSelectedService(service);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <StyledAppBar position="static">
        <Toolbar>
          <Logo src={Thrivelogo} alt="The Thrive Hub Logo" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            The Thrive Hub
          </Typography>
          <Typography variant="body1" color="black" sx={{ marginRight: 2 }}>
            Site Under Maintenance
          </Typography>
          <Button color="inherit" href="https://calendly.com/thethrivehub" target="_blank">
            Book a call
          </Button>
        </Toolbar>
      </StyledAppBar>

      <HeroSection>
        <Container>
          <Typography variant="h2" component="h1" gutterBottom>
            Welcome to The Thrive Hub
          </Typography>
          <Typography variant="h5" component="p" gutterBottom>
            Empowering small businesses with comprehensive accounting and support services
          </Typography>
        </Container>
      </HeroSection>

      <Container sx={{ my: 8 }}>
        <Typography variant="h3" component="h2" gutterBottom textAlign="center">
          Our Services
        </Typography>
        <Grid container spacing={4}>
          {services.map((service) => (
            <Grid item xs={12} sm={6} md={4} key={service.title}>
              <ServiceCard onClick={() => handleCardClick(service)}>
                <CardLogo src={Thrivelogo} alt="The Thrive Hub Logo" />
                <CardMedia
                  component="img"
                  height="200"
                  image={service.image}
                  alt={service.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </ServiceCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Box sx={{ bgcolor: "background.paper", py: 8 }}>
        <Container>
          <Typography variant="h4" component="h3" gutterBottom textAlign="center">
            Contact Us
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection={{ xs: "column", sm: "row" }} gap={2}>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" sx={{ ml: 1 }}>
                Tel. 01926 919035
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" sx={{ ml: 1 }}>
                Suite 82, James Carter Road, IP28 7DE
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" mt={4}>
            <Button variant="contained" color="primary" href="https://calendly.com/thethrivehub" target="_blank">
              Book a call
            </Button>
          </Box>
        </Container>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedService?.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {selectedService?.description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            For more information about our {selectedService?.title.toLowerCase()} services, please contact us:
          </Typography>
          <Box mt={2}>
            <Typography variant="body2">
              Tel. 01926 919035
            </Typography>
            <Typography variant="body2">
              Suite 82, James Carter Road, IP28 7DE
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
          <Button href="https://calendly.com/thethrivehub" target="_blank" variant="contained" color="primary">
            Book a call
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HomePage;
